import React from 'react';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";

export const FaqPageTemplate = () => (
  <main role="main" className="faq-page">
    <Helmet>
      <title>FAQ</title>
    </Helmet>
    <div>
      {/* Begin Banner */}
      <div className="jumbotron faq mb-0" style={{color: 'white', textAlign: 'center', "backgroundImage": "linear-gradient(rgba(63, 62, 62, 0.47), rgba(63, 62, 62, 0.47)), url(/assets/img/faqbanner.jpg)"}}>
        {/* <h2 style="color:white;">FAQ</h2> */}
        <h1 className="display-5 text-white text-center" style={{fontSize: '7rem'}}>Coming Soon</h1>
      </div>
      {/* End Banner */}
      {/* Begin Second section */}
      <div className="container features wd-75 card ca-shadow-sm pt-4 pb-4 mt-n4 mb-10 rounded-2">
        <div className="row">
          <div className="col">
            <div className="points-text pt-5 pr-5 pl-5">
              <h3> The standard Lorem Ipsum passage, used since the 1500s</h3>
              <p> "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
            </div>
            <div className="points-text pt-5 pr-5 pl-5">
              <h3> The standard Lorem Ipsum passage, used since the 1500s</h3>
              <p> "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
            </div>
            <div className="points-text pt-5 pr-5 pl-5">
              <h3> The standard Lorem Ipsum passage, used since the 1500s</h3>
              <p> "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* End Second section */}
      <hr />
      <div className="container jumbotron jumbotron-f" style={{background: '#f0f4f8 !important'}}>
        <div className="row">
          <div className="col-md">
            <h2>Make appointment for our Therapist now!</h2>
          </div>
          <div className="col-md justify-middle">
            <div className="row">
              <div className="col-md">
                <a className="btn btn-gr pl-3 pr-3 visit-btn mr-3 fw-6 schedule" data-toggle="modal" data-target="#FormModalCenter" role="button">Schedule visit</a>
              </div>
              <div className="col-md mobile-pt">
                <a className="fw-6 p-2" style={{fontWeight: 'bolder', fontSize: '16px', color: '#00234e'}}><i className="fa fa-phone" />888-859-0145</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);


const FaqPage = () => {
  return (
    <Layout>
      <FaqPageTemplate/>
    </Layout>
  )
};

export default FaqPage;
